.container {
  margin: 0px;
}
.promocodes {
  padding-top: 10px;
  margin-bottom: 10px;
}
.promocodes2 {
  margin-bottom: 40px;
  position: relative;
}

.padding {
  padding: 10px;
}
.TopEnableButton {
  // margin-left: 20px;
}
.redemptionStyle {
  margin-bottom: 10px;
  margin-top: 15px;
}
.subtitleTextStyle {
  margin-top: 10px;
}
.Rewardvalue {
  margin-bottom: 10;
  margin-top: 10;
}
.CardSection {
  margin: 20px;
  margin-left: 0px;
}
.cardbackground {
  width: "40%";
  background: "white";
  margin-bottom: 10;
}

//half Form

.midContainer {
  padding-top: 10px;
}
.CardSize {
  height: 80px;
  width: 160px;
}
.cardmainContainer {
  flex-direction: row;
  display: flex;
}
.cardmidContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}
.dropBox {
  padding-top: 10px;
}
.switch {
  padding-right: 10px;
  align-items: center;
  text-decoration: none;
}
.timeTextContainer {
  display: flex;
  flex-direction: row;
  // align-items: center;
  // justify-content: center;
}
.timeText {
  // justify-content: space-around;
  width: 33%;
}
.timeSelectionContainer {
  display: flex;
  flex-direction: row;
}
.timeTextTime {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50%;
}
.buttonBox {
  flex-direction: row;
  padding-bottom: 15px;
  padding-top: 15px;
  display: flex;
}
.redBtn {
  color: #bf0711;
  padding-left: 10px;
}
.limitedTimeHead {
  border-color: #bf0711;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.head {
  padding-top: 15px;
  padding-bottom: 5px;
}
