.newsletter__preview {
  .newsletter__preview-image {
    width: 50%;
    .Polaris-SkeletonThumbnail {
      min-height: 300px;
    }
  }
  .newsletter__preview-content {
    width: 50%;
    padding: 24px;
    .newsletter__preview-form {
      display: flex;
      width: 100%;
      justify-content: space-between;
      > div {
        width: calc(100% - 100px);
      }
      .Polaris-Button {
        width: 92px;
      }
    }
  }

  &.layout-full {
    .newsletter__preview-image {
      .Polaris-SkeletonThumbnail {
        min-height: 300px;
      }
    }
  }
  &.layout-none {
    padding: 60px 0;
    text-align: center;
  }
}
