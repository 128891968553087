.design-options__colors {
  margin-top: 20px;
  .Polaris-Card {
    padding: 18px 20px;
    margin-top: 10px;
    .design-settings__button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .label {
        display: flex;
        align-items: center;
        .selected-color {
          width: 44px;
          height: 24px;
          border-radius: 4px;
          border: 1px solid #a7acb1;
          margin-right: 16px;
          cursor: pointer;
        }
        h3 {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}