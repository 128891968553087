$border-color:#E4E5E7;
.promo__preview {
  padding: 10px;
  border: 1px solid $border-color;

  .promo__preview-content {
    padding:0;
    text-align:left !important;
    .promo__preview-heading{
      font-weight:400;
    }
    .promo__preview-form {
      display: flex;
      width: 100%;
      justify-content: space-between;
      > div {
        width: calc(100% - 100px);
      }
      .Polaris-Button {
        width: 92px;
      }
    }
  }

  &.layout-image {
    .promo__preview-image {
      width: 130px;
      
      .Polaris-SkeletonThumbnail {
        min-height: unset;
        width:100%;
        height:130px !important;
      }
    }
    .promo__preview-content{
      width: calc(100% - 130px);;
      padding: 0;
      padding-left: 16px;

    }
  }
}
