//SETTINGS COMPONENT
#design-page{
.design-settings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .design-settings__left {
    width: calc(50% - 12px);
    .design-options {
    }
  }
  .design-settings__right {
    width: calc(50% - 12px);
  }
  .design-options {
    padding: 30px;
    .Polaris-Stack {
      margin-top: 0;

      .Polaris-Stack__Item {
        margin: 0px 0px 8px 0px !important;
        label.design-options__label.label {
          font-size: 13px;
          text-transform: uppercase;
          font-weight: 600;
          margin-bottom: 16px;
          display: block;
          line-height: 1.1;
        }
      }
    }
    .design-options__colors {
      margin-top: 20px;
      .Polaris-Card {
        padding: 18px 20px;
        margin-top: 10px;
        .design-settings__button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .label {
            display: flex;
            align-items: center;
            .selected-color {
              width: 44px;
              height: 24px;
              border-radius: 4px;
              border: 1px solid #a7acb1;
              margin-right: 16px;
              cursor: pointer;
            }
            h3 {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  //SETTINGS SKELETON RIGHT
  .settings-skeleton {
    position: sticky;
    top: 0;
    .Polaris-Card__Section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .settings-skeleton__header {
        margin-bottom: 16px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        & > * {
          width: calc(20% - 8px);
        }
        .skeleton-header__nav {
          width: calc(60% - 8px);
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
            width: 70px;
            margin: 0 5px;
          }
        }
        .skeleton-header__icons {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .Polaris-SkeletonThumbnail {
            width: 30px;
            height: 30px;
            margin-left: 4px;
          }
        }
      }
      .settings-skeleton__sidebar {
        width: calc(20% - 16px);
        .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
          margin-bottom: 18px;
        }
      }
    }
    .settings-skeleton__main {
      width: calc(80% - 16px);
      .settings-skeleton__main-section {
        &.section-hero {
          .Polaris-SkeletonThumbnail {
            width: 100%;
            height: 150px;
          }
        }
        &.section-featured__collection {
          display: flex;
          margin: 15px 0;
          justify-content: space-between;
          padding: 15px 10px;
          .section-featured__collection-left {
            width: calc(50% - 12px);
            .settings-skeleton__heading {
              margin-bottom: 10px;
              max-width: 80%;
            }
            .settings-skeleton__button {
              margin-top: 16px;
            }
          }
          .section-featured__collection-right {
            width: calc(50% - 12px);
            .Polaris-SkeletonThumbnail {
              width: 100%;
              height: 100%;
            }
          }
        }
        &.section-newsletter {
          display: flex;
          margin: 15px 0;
          justify-content: space-between;
          padding: 15px 10px;
          .section-newsletter-left {
            width: calc(50% - 12px);
            .Polaris-SkeletonThumbnail {
              width: 100%;
              height: 100%;
            }
          }
          .section-newsletter-right {
            width: calc(50% - 12px);
            .settings-skeleton__heading {
              margin-bottom: 10px;
              max-width: 80%;
            }
            .settings-skeleton__button {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}
}