.image-section__preview {
  .image-section__preview-image {
    width: 50%;
    .Polaris-SkeletonThumbnail {
      min-height: 300px;
    }
  }
  .image-section__preview-content {
    width: 50%;
    padding: 24px;
  }

  &.layout-full {
    .image-section__preview-image {
      .Polaris-SkeletonThumbnail {
        min-height: 300px;
      }
    }
  }
  &.layout-none {
    padding: 60px 0;
    text-align: center;
  }
}
