.Polaris-Page-Header__TitleWrapper {
  margin-bottom: 14px;
  h1.Polaris-Header-Title {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .Polaris-Header-Title__SubTitle {
    font-size: 20px;
  }
}
.langauge-form {
  .Polaris-Card {
    padding: 30px;
  }
}
