$primary-color: #235eaa;

.wishlist-header_preview {
  position: relative;
  .wishlist-header_preview-button {
    &.wishlist-button__floating {
      position: absolute;
      z-index: 1;
      right: 20px;
      bottom: 20px;
      span.Polaris-Thumbnail {
        width: 50px;
        height: 50px;
        background: $primary-color;
        box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        --p-icon-subdued: #ffffff;
      }
    }
  }
  .wishlist-header__preview__header {
    margin: 16px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    & > * {
      width: calc(20% - 8px);
    }
    .wishlist-header__preview__nav {
      width: calc(60% - 8px);
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .Polaris-SkeletonBodyText__SkeletonBodyTextContainer {
        width: 70px;
        margin: 0 5px;
        .Polaris-SkeletonBodyText {
          height: 18px;
        }
      }
      .wishlist-button__menu {
        margin: 0 5px;
        color: $primary-color;
      }
    }
    .wishlist-header__preview__icons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .Polaris-SkeletonThumbnail {
        width: 30px;
        height: 30px;
        margin-left: 4px;
      }
    }
    .wishlist-button__icon {
      margin-left: 4px;
      display: flex;
      align-items: center;
      color: $primary-color;
      span.Polaris-Thumbnail {
        width: 30px;
        height: 30px;
        min-width: unset;
        --p-icon-subdued: $primary-color;
        background: none;
        border: none;
      }
    }
  }
  .wishlist-header__main-section {
    width: 100%;
    .Polaris-SkeletonThumbnail {
      min-height: 400px;
      width: 100%;
    }
  }
}
