.container {
  width: 50vw;
  display: flex;
  flex-direction: column;
  padding: 2%;
}
.midContainer {
  margin-top: 2%;
  margin-bottom: 2%;
}
.switch {
  width: 25%;
}
.collectionHead {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: #eee 2px solid;
  border-radius: 3px;
  padding: 1%;
}
.collectionHeadIcons {
  display: flex;
  flex-direction: row;
  width: 75vw;
  justify-content: flex-start;
}
.collectionHeadbtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50vw;
}
.collectionContain {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-left: #eee 2px solid;
  border-right: #eee 2px solid;
  border-bottom: #eee 2px solid;
  border-radius: 3px;
  padding: 1%;
  color: blue;
}
.resultBox {
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  margin-left: 27vw;
}
.radio {
  margin-top: 4%;
  margin-bottom: 3%;
}
.popoverHead {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 20px;
  text-decoration: none;
}
.btnContain {
  text-decoration: none;
}
