.settings-list {
  .Polaris-Card {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
    > .Polaris-Link {
      width: calc(50% - 20px);
      display: flex;
      margin-bottom: 16px;
      align-items: center;
      .Polaris-TextContainer {
        margin-left: 16px;
      }
    }
  }
}

.settings-skeleton * {
  transition: none;
  animation: none  !important;
}
