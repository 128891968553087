$color: darkblue;
$textcolor: #fff;

.container {
  padding-left: 20px;
  padding-top: 20px;
}
.promocodes {
  padding-top: 10px;

  margin-bottom: 10px;
}
.promocodes2 {
  margin-bottom: 40px;
  position: relative;
}

.padding {
  padding: 10px;
}

//HalfForm
.midContainer {
  padding-left: 15px;
  padding-top: 10px;
}
.CardSize {
  height: 80px;
}
.cardmainContainer {
  flex-direction: row;
  display: flex;
}
.cardmidContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}
.dropBox {
  padding-top: 10px;
}
.switch {
  padding-right: 10px;
  align-items: center;
  text-decoration: none;
}
.timeTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.timeText {
  justify-content: space-around;
}
.timeTextTime {
  padding-top: 10px;
  padding-bottom: 10px;
}
.EditComp {
  padding-top: 30px;
  padding-bottom: 10px;
}
.buttonBox {
  flex-direction: row;
  padding-bottom: 15px;
  padding-left: 0px;
  padding-top: 15px;
  display: flex;
}
.redBtn {
  color: #bf0711;
  padding-left: 10px;
}
.limitedTimeHead {
  border-color: #bf0711;
  padding-top: 20px;
}
.head {
  padding-top: 15px;
  padding-bottom: 5px;
}

//menuPage
.cardContainer {
  padding: 20px;
}
.menuRadio {
  display: flex;
  flex-direction: column;
}
.menuItems {
  padding-top: 25px;
  padding-bottom: 15px;
}
.BoxForInstructions {
  border: 1px solid red;
  border-radius: 10px;
  background-color: rgb(235, 211, 179);
}
.InsContainer {
  padding: 30px;
  padding-bottom: 5px;
  padding-top: 15px;
}

.mainRadioBox {
  display: flex;
  flex-direction: row;
}
.midRadioBox {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.RadioButtonUnFix {
  padding: 1px;
  margin: 1px;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.topBorderLine {
  border-top: #D2D5D8 solid 1px;
  padding: 10px;
}
.DarkBg {
  background-color: $color;
  color: $textcolor;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  align-items: center;
}
