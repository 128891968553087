.demo-editor {
  height: 75px;
  border: 1px solid #F1F1F1;
}


.demo-editor:hover {
  cursor: auto;
}

.rdw-editor-toolbar {
  background-color: #FAFBFB;
  margin-bottom: 0px;
  
}
.rdw-option-wrapper.rdw-option-active {
    background-color: #E4E5E7;
}


