$border-color:#E4E5E7;
.featured-items__preview   {
  .featured-items__preview-heading {
    width: calc(100% - 16px);
    margin: 0 auto;
    text-align: center;
}
  .featured-items__preview-item {
    width: calc(24% - 16px);
    margin: 0 auto;
    .Polaris-SkeletonThumbnail {
      height: 120px;
      width: auto;
  }
}
&.skeleton__preview-mobile{
  .featured-items__preview-item {
    width: calc(100% - 16px);
    margin-bottom: 16px;
  }
}
}
