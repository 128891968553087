$primary-color: #222b36;
$border-color: #e4e5e7;
.skeleton__preview-header {
  .Polaris-OptionList__Options {
    display: flex;
    max-width: 230px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    li {
      margin: 0 8px;
      --p-icon-subdued:$primary-color;
      button {
        &:focus,
        &:active {
          outline: none !important;
          border: none !important;
        }
        &.Polaris-OptionList-Option--select::before {
          top: auto;
          bottom: -0.3rem;
          left: 0;
          width: 100%;
          height: 0.3rem;
        }
      }
    }
  }
}
.skeleton__preview * {
  animation: none !important;
  transition: none;
}
.skeleton__preview {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .skeleton__preview-image {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
    .skeleton__preview-heading {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 16px;
      > span * {
        display: inline-block;
        margin-right: 4px;
      }
    }
    .skeleton__preview-subheading {
      margin-bottom: 16px;
      font-size: 13px;
      > span* {
        display: inline-block;
        margin-right: 4px;
      }
    }
    .Polaris-Button {
      background-color: $primary-color;
      color: white;
      span {
        text-transform: uppercase;
        font-size: 12px;
      }
    }
  &.layout-right {
    flex-direction: row-reverse;
  }
  &.layout-full {
    position: relative;
    .skeleton__preview-image {
      width: 100%;
      .Polaris-SkeletonThumbnail {
        width: 100%;
      }
    }
    .skeleton__preview-content {
      --p-surface-neutral: #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }
  &.layout-none {
    .skeleton__preview-content {
      width: 100%;
      text-align: center;
      * {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &.skeleton__preview-mobile {
    flex-wrap: wrap;
    position: relative;
    .skeleton__preview-image {
      width: 100%;
      order: 0;
    }
    .skeleton__preview-content {
      width: 100%;
      text-align: center !important;
      padding: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 500px;
    }
  }
}
