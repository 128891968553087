.Polaris-Page-Header__TitleWrapper {
  margin-bottom: 14px;
  h1.Polaris-Header-Title {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .Polaris-Header-Title__SubTitle {
    font-size: 20px;
  }
}
.billing-header {
  .header {
    display: flex;
    padding: 20px 30px;
    align-items: center;
    position: relative;
    justify-content: space-between;
    .header__left {
      display: flex;
      align-items: center;
      h2 {
        font-size: 28px;
        margin-right: 8px;
        font-weight: 600;
      }
    }
    .header__right {
      .package-price {
        font-size: 28px;
      }
    }
  }
}
.billing-main {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .Polaris-Card {
    padding: 20px;
    margin: 0 !important;
    width: calc(33% - 8px);
    .package-type {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
      font-weight: 600;
      display: flex;
      align-items: center;
      .Polaris-Badge {
        margin-left: 8px;
      }
    }
    .package-price {
      font-size: 28px;
    }
    .package-features {
      padding: 0;
      margin: 30px 0;
      .package-feature__item {
        display: flex;
        align-items: center;
        line-height: 32px;
        justify-content: flex-start;
        span.Polaris-Icon {
          margin: 0;
          margin-right: 12px;
          svg {
            fill: #235eaa !important;
          }
        }
      }
    }
  }
}

.billing-carousel {
  margin: 30px 0;
  .Polaris-Card {
    margin: 5px;

    .slide {
      padding: 80px;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      span.Polaris-Thumbnail {
        margin-bottom: 40px;
      }
      h4 {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 600;
      }
      .slide-rating {
        display: flex;
        margin-bottom: 10px;
      }
      .slide-review {
        width: 90%;
        max-width: 500px;
        margin-bottom: 40px;
        color: #5c5f62;
      }
    }
  }
  .react-multiple-carousel__arrow {
    background: none !important;
    &:before {
      color: #5c5f62;
    }
  }
  .react-multi-carousel-dot-list {
    bottom: 80px;
    .react-multi-carousel-dot {
      button {
        margin: 0 8px;
        border: none;
        background: rgba(17, 18, 19, 0.24);
        width: 6px;
        height: 6px;
      }
      &.react-multi-carousel-dot--active button {
        background: #235eaa;
      }
    }
  }
}
.billing-contact {
  margin: 30px 0;
  .billing-contact__info {
    padding: 50px;
    text-align: center;
    h3 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
    }
  }
}
