$primary-color: #235eaa;
$disabled-color: rgba(0, 0, 0, 0.3);

.orders_preview {
    justify-content: space-between !important;
    align-items: flex-start !important;
    overflow: hidden;
    .orders_preview-header{
        width: 100%;
    display: flex;
    
    margin: 32px 0;
    align-items: center;
    justify-content: space-between;
    .orders_preview-header__left{
        .Polaris-SkeletonDisplayText__DisplayText {
            min-width:150px;
        }
    }
    .orders_preview-header__right{
        display: flex;
        width: 50%;
        .orders_preview-header__right-item{
            width: calc(33.3% - 8px);
            margin: 0 auto;
            .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
                margin-bottom:8px;
                .Polaris-SkeletonBodyText {
                    height: 4px;
                    position: relative;
                    width: 80%;
                }
            }
            .Polaris-SkeletonDisplayText--sizeMedium {
                height: 8px;
            }
        }
    }
    }
    .orders_preview-left {
        width: calc(75% - 16px);
        display: flex;

        .Polaris-SkeletonThumbnail {
            width: calc(33% - 4px);
            height: 150px;
            margin: 0 auto;
        }
    }

    .orders_preview-right {
        width: calc(25% - 16px);
        .orders_preview-mobile__button{
            display:none;
        }
        .Polaris-SkeletonDisplayText__DisplayText{
            margin-bottom:8px;
            max-width: 100%;
        }
    }

    .orders_preview-footer {
        width: 100%;
        display: flex;
        margin-top: 16px;
        justify-content: space-between;
        button{
            background-color: $primary-color;
        }
        .Polaris-SkeletonDisplayText__DisplayText{
            width:100%;
        }
    }


    &.orders_preview-mobile {
        .orders_preview-header{
            .orders_preview-header__right{
                justify-content: flex-end;
                .orders_preview-mobile__button{
                    display:block;
                    min-width: 150px;
                    .Polaris-SkeletonDisplayText__DisplayText{
                        max-width: 100%;
                    }
                }
                .orders_preview-header__right-item{
                    display:none;
                }
            }
        }
        .orders_preview-left {
            width: 100%;
            margin-bottom: 16px;
            .Polaris-SkeletonThumbnail {
                width: calc(25% - 4px);
                margin: 0 4px;    
            }
        }
        .orders_preview-right {
            width: 100%;
        }
        .orders_preview-footer{
            justify-content: center;
            .Polaris-SkeletonDisplayText__DisplayText{
                display:none
            }
        }
    }
}