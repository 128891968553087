$primary-color: #235eaa;
$disabled-color: rgba(0, 0, 0, 0.3);

.wishlist-product_preview {
  position: relative;
  .wishlist-product_preview-button {
    &.wishlist-button__floating {
      position: absolute;
      z-index: 1;
      right: 20px;
      bottom: 20px;
      span.Polaris-Thumbnail {
        width: 50px;
        height: 50px;
        background: $primary-color;
        box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        --p-icon-subdued: #ffffff;
      }
    }
  }
  .skeleton__preview-image {
    width: calc(50% - 16px);
    margin: 0 auto;
    .Polaris-SkeletonThumbnail {
      min-height: 300px;
      width: 100%;
    }
  }
  .skeleton__preview-content {
    width: calc(50% - 16px);
    margin: 0 auto;
    .skeleton__preview-heading,
    .skeleton__preview-subheading,
    .skeleton__preview-description {
      color: $disabled-color;
    }
    .skeleton__preview-description {
      margin-bottom: 16px;
    }
    .wishlist-product_preview-buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      button {
        width: 100%;
        border: none;
        .Polaris-Button__Text {
          display: flex;
          align-items: center;
          justify-content: center;
          span.Polaris-Icon {
            margin: 0;
            margin-right: 8px;
            width: 15px;
            height: 15px;
          }
        }
      }
      .wishlist_button {
        margin-top: 8px;
        width: 100%;
        button {
          background: inherit;
          color: inherit;
        }
      }
    }
  }
  &.wishlist-product_preview-style3 {
    .wishlist-product_preview-buttons {
      flex-wrap: nowrap;
      .wishlist_button {
        width: auto;
        margin: 0;
        margin-left: 8px;
        span.Polaris-Icon {
          margin: 0 !important;
        }
      }
    }
  }
  &.wishlist-product_preview-mobile{
    .skeleton__preview-image {
        width:100%;
    }
    .skeleton__preview-content{
      width:100%;
      margin-top:16px;
    }
  }
}
