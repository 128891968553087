//simple form box

.title {
  flex-direction: row;
  display: flex;
  padding: 15px;
  align-items: center;
}
.componentBox {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}
.componentBoxSlider {
  padding-top: 20px;
  padding-right: 25px;
  padding-bottom: 20px;
}
.buttonBox {
  flex-direction: row;
  padding-bottom: 15px;
  padding-left: 25px;
}
.switchGiftcards {
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;
  text-decoration: none;
}
.progessBar {
  padding-left: 25px;
}
.textFieldStyle {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.resultBox {
  // padding-left: 70%;
  margin-right: 15px;
}
.popoverHead {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 20px;
}
.btnContain {
  flex-grow: 1;
  text-decoration: none;
}
.cardContain {
  flex-direction: column;
}
