$primary-color: #235eaa;
$disabled-color: rgba(0, 0, 0, 0.3);

.wishlist-grid_preview {
  position: relative;

  .wishlist-grid_preview-item {
    position: relative;
    width: calc(33% - 16px);
    margin: 0 auto;
    .wishlist-grid_preview-button {
      position: absolute;
      width: 20px;
      height: 20px;
      z-index: 1;
      padding: 4px;
      border-radius: 50%;
      top: 5px;
      left: 5px;
    }
    .skeleton__preview-image {
      width: 100%;
      margin: 0 auto;
      position: relative;
      .Polaris-SkeletonThumbnail {
        min-height: 300px;
        width: 100%;
      }
    }
    .skeleton__preview-content {
      width: 100%;
      margin: 16px auto;
      .skeleton__preview-heading,
      .skeleton__preview-subheading {
        color: $disabled-color;
      }
      button {
        width: 100%;
        border: none;
        .Polaris-Button__Text {
          span.Polaris-Icon {
            margin: 0;
            margin-right: 8px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
  &.wishlist-grid_preview-style-top_right {
    .wishlist-grid_preview-button {
      right: 5px;
      top: 5px;
      bottom: auto;
      left: auto;
    }
  }
  &.wishlist-grid_preview-style-bottom_right {
    .wishlist-grid_preview-button {
      right: 5px;
      top: auto;
      bottom: 5px;
      left: auto;
    }
  }
  &.wishlist-grid_preview-style-bottom_left {
    .wishlist-grid_preview-button {
      right: auto;
      top: auto;
      bottom: 5px;
      left: 5px;
    }
  }
  &.wishlist-grid_preview-mobile {
    .wishlist-grid_preview-item {
      width: 100%;
    }
  }
}
