$border-color: #e4e5e7;
.review-section__preview {
  .review-section__preview-heading {
    width: calc(100% - 16px);
    margin: 0 auto;
    text-align: center;
  }
  .review-section__preview-item {
    width: calc(24% - 16px);
    margin: 0 auto;
    .Polaris-SkeletonThumbnail {
      height: 120px;
      width: auto;
    }
    .Polaris-Button  {
      margin-top: 16px;
    }
  }
  &.skeleton__preview-mobile {
    .review-section__preview-item {
      width: calc(100% - 16px);
      margin-bottom: 16px;
    }
  }
}
