.page-wrapper {
  //VARIABLES
  --icon-color: #5c5f62;
  --subtitle-color: #6d7175;
  --border-color:#D9D9DA;
  //GENERAL
  .Polaris-Badge {
    margin-left: 8px;
  }
  .Polaris-Button__Content {
    span.Polaris-Button__Icon {
      margin-right: 10px;
      margin-left: 0;
    }
    .Polaris-Button__Text {
      font-size: 14px;
    }
  }
  //HEADER
  width: calc(100% - 48px);
  margin: 0 auto;
  padding: 60px 0;
  .page-header {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .page-header__left {
      h1.page-header__title {
        font-weight: 600;
        font-size: 28px;
        line-height: 1.2;
        margin-bottom: 10px;
      }
      p.page-header__subtitle {
        font-size: 20px;
        line-height: 1.4;
        color: var(--subtitle-color);
      }
    }
    .page-header__right {
      button {
        margin-left: 8px;
      }
    }
    
  }
  // FOOTER
  .page-footer-button {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    .page-footer-button__element {
      margin-left: 15px;
    }
  }
  .page-separator {
    margin-bottom: 30px;
    margin-top: 0;
    height: 1px;
    background-color: #D9D9DA;
    border: none;
    
  }
}
